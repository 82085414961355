import setupFlickity from '../utils/setupFlickity';
import onDomReady from '../utils/onDomReady';

const removeActive = (e) => {
  for (let i = 0; i < e.length; i += 1) {
    e[i].classList.remove('active');
  }
};

const reportingHighlightsInit = () => {
  const tabsContainer = document.querySelector('.reportingHighlights');
  if (tabsContainer) {
    const tabs = tabsContainer.querySelectorAll("[data-toggle='tab']");
    const sliderContainer = tabsContainer.querySelectorAll('.sliderContainer');

    for (let i = 0; i < tabs.length; i += 1) {
      setupFlickity('.reportingHighlights', {
        groupCells: true,
        freeScroll: true,
        contain: true,
        pageDots: false,
      }, `.sliderContainer:nth-of-type(${i + 1}) .carousel`);
      tabs[i].addEventListener('click', function (e) {
        e.preventDefault();
        removeActive(tabs);
        removeActive(sliderContainer);
        tabsContainer.querySelector(tabs[i].hash).classList.add('active');
        this.classList.add('active');
      });
    }
  }
};

onDomReady(reportingHighlightsInit);
